import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TiivliAPI from "../TiivliAPI";
// import TiivliAPI from "./TiivliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  Card,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ApplicationState from "../ApplicationState";
import { DeleteForever, Edit } from "@mui/icons-material";
import { Statuses } from "../Enums";
import TMDBAPI from "../TMDBAPI";
import logoAmazonPrime from "../logo_amazonprime.webp";
import logoAppleTVPlus from "../logo_appletvplus.webp";
import logoCmore from "../logo_cmore.webp";
import logoDisneyPlus from "../logo_disneyplus.webp";
import logoHBOMax from "../logo_hbomax.webp";
import logoNetflix from "../logo_netflix.webp";
import logoSkyShowtime from "../logo_skyshowtime.webp";
import logoSvtPlay from "../logo_svtplay.webp";
import logoTv4Play from "../logo_tv4play.webp";
import logoViaplay from "../logo_viaplay.webp";

function ListItemView(props) {
  let history = useNavigate();
  let params = useParams();

  const [listItem, setListItem] = useState([]);
  const [genres, setGenres] = useState([]);
  const [imdbLink, setImdbLink] = useState([]);
  const [playpilotLink, setPlaypilotLink] = useState([]);
  const [wikipediaLink, setWikipediaLink] = useState([]);
  const [posterPath, setPosterPath] = useState(undefined);
  const [backdropPath, setBackdropPath] = useState(undefined);
  const [overview, setOverview] = useState(undefined);
  const [episodes, setEpisodes] = useState(undefined);
  const [seasons, setSeasons] = useState(undefined);
  const [serviceLogo, setServiceLogo] = useState(undefined);
  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      let r = await TiivliAPI.getListItem(params.id);
      setListItem(r);

      let g = await TiivliAPI.getGenresForListItem(params.id);
      setGenres(g);

      let imdbId = 0;

      if (r.type == "Movie" && r.tmdbId) {
        let movie = await TMDBAPI.getMovieById(r.tmdbId);
        imdbId = await TMDBAPI.getMoviesImdbId(r.tmdbId);
        if (movie) {
          //await TMDBAPI.getConfig();
          setPosterPath(
            "http://image.tmdb.org/t/p/" + "w154" + movie.poster_path
          );
          setBackdropPath(
            "http://image.tmdb.org/t/p/" + "w300" + movie.backdrop_path
          );
          setOverview(movie.overview);
          setGenres(movie.genres);
        }
      }
      if (r.type == "Series" && r.tmdbId) {
        let serie = await TMDBAPI.getSeriesById(r.tmdbId);
        imdbId = await TMDBAPI.getSeriesImdbId(r.tmdbId);
        if (serie) {
          // console.log(await TMDBAPI.getConfig());
          setPosterPath(
            "http://image.tmdb.org/t/p/" + "w154" + serie.poster_path
          );
          setBackdropPath(
            "http://image.tmdb.org/t/p/" + "w300" + serie.backdrop_path
          );
          setOverview(serie.overview);
          setGenres(serie.genres);
          setEpisodes(serie.number_of_episodes);
          setSeasons(serie.number_of_seasons);
        }

        if (r.service == "?") {
          //setServiceLogo(logoNetflix);
        }
        if (r.service == "Netflix") {
          setServiceLogo(logoNetflix);
        }
        if (r.service == "Amazon Prime") {
          setServiceLogo(logoAmazonPrime);
        }
        if (r.service == "Apple TV+") {
          setServiceLogo(logoAppleTVPlus);
        }
        if (r.service == "Cmore") {
          setServiceLogo(logoCmore);
        }
        if (r.service == "HBO Max") {
          setServiceLogo(logoHBOMax);
        }
        if (r.service == "SVT Play") {
          setServiceLogo(logoSvtPlay);
        }
        if (r.service == "TV4 Play") {
          setServiceLogo(logoTv4Play);
        }
        if (r.service == "SkyShowtime") {
          setServiceLogo(logoSkyShowtime);
        }
        if (r.service == "Viaplay") {
          setServiceLogo(logoViaplay);
        }
        if (r.service == "Disney+") {
          setServiceLogo(logoDisneyPlus);
        }
      }

      setImdbLink(
        "https://www.imdb.com/find/?q=" +
          r.name.replace(" ", "%20") +
          " ID " +
          imdbId
      );
      setImdbLink("https://www.imdb.com/title/" + imdbId);

      setWikipediaLink(
        "https://en.wikipedia.org/w/index.php?search=" +
          r.name.replace(" ", "+") +
          "+tv+series&title=Special%3ASearch&ns0=1"
      );
      setPlaypilotLink(
        "https://www.playpilot.com/se/search/?q=" +
          r.name.replace(" ", "+") +
          "&t=titles"
      );
    };
    load1();
  }, []);

  function addStatusButton(newStatus) {
    return (
      <Box p={0}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ color: "#d4af37", marginTop: "8px" }}
          onClick={async () => {
            let updatedItem = listItem;
            updatedItem.status = newStatus;

            await TiivliAPI.updateListItem(updatedItem);
            history("/list");
          }}
        >
          Mark as {newStatus}
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Card
        style={{ backgroundColor: "white", margin: "16px", marginTop: "32px" }}
      >
        <CardMedia
          component="img"
          height="194"
          image={backdropPath}
          alt="Backdrop"
        >
          {/* <img src={posterPath} /> */}
        </CardMedia>
        <Box p={2}>
          <Typography variant="h4">{listItem.name}</Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box style={{ paddingRight: "8px" }}>
              <img src={serviceLogo} width="32px" style={{borderRadius: "4px"}} />
            </Box>
            {listItem.type == "Series" && seasons != 1 && (
              <Typography variant="subtitle1">
                {seasons} seasons {episodes} episodes
              </Typography>
            )}
            {listItem.type == "Series" && seasons == 1 && (
              <Typography variant="subtitle1">
                {seasons} season {episodes} episodes
              </Typography>
            )}
          </Grid>
          {/* <Typography variant="subtitle1">
            {listItem.status}
            {" - "}
            {listItem.type}
            {" on "}
            {listItem.service}
          </Typography> */}
        </Box>

        {/* <Box p={2}>
          <img src={posterPath} />
        </Box> */}

        <Box p={2}>
          <Typography variant="subtitle2">{overview}</Typography>
          <br />
          {listItem.comment && "Series" && listItem.comment.length > 0 && (
            <Typography variant="subtitle1">{listItem.comment}</Typography>
          )}
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{paddingTop: "24px"}}
          >
            {genres &&
              genres.length > 0 &&
              genres
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((genre, index) => {
                  let isLastGenre = index === genres.length - 1;
                  let separator = isLastGenre ? "" : ", ";
                  return (
                    <Typography variant="subtitle2" style={{paddingRight: "4px"}}>
                      {genre.name}{separator}
                      </Typography>
                  );
                })}
          </Grid>
          <br />
          {listItem.status == Statuses.Ready && (
            <React.Fragment>
              {addStatusButton(Statuses.Watching)}
            </React.Fragment>
          )}
          {listItem.status == Statuses.Watching && (
            <React.Fragment>
              {addStatusButton(Statuses.Paused)}
              {addStatusButton(Statuses.Finished)}
            </React.Fragment>
          )}
          {listItem.status == Statuses.Paused && (
            <React.Fragment>
              {addStatusButton(Statuses.Watching)}
              {addStatusButton(Statuses.Finished)}
            </React.Fragment>
          )}
          {listItem.status == Statuses.Finished && (
            <React.Fragment>{addStatusButton(Statuses.Ready)}</React.Fragment>
          )}
          <br />
          {listItem.weekdayNewEpisodes &&
            listItem.weekdayNewEpisodes.length > 0 && (
              <React.Fragment>
                New episodes on {listItem.weekdayNewEpisodes}
                <br />
              </React.Fragment>
            )}
          {listItem.nextSeasonDate && (
            <React.Fragment>
              Next season arrives{" "}
              {new Date(listItem.nextSeasonDate).toLocaleDateString("sv-SE")}
              <br />
            </React.Fragment>
          )}
          {listItem.removalDate && (
            <React.Fragment>
              Will be removed{" "}
              {new Date(listItem.removalDate).toLocaleDateString("sv-SE")}
              <br />
            </React.Fragment>
          )}

          <Link href={imdbLink} target="_blank">
            Read more at IMDB
          </Link>
          <br />
          <Link href={wikipediaLink} target="_blank">
            Read more at Wikipedia
          </Link>
          <br />
          <Link href={playpilotLink} target="_blank">
            Read more at Playpilot
          </Link>
        </Box>
        <br />
        <br />
        <Divider></Divider>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ marginTop: "8px" }}
        >
          <Box p={1}>
            <Button
              variant="contained"
              color="primary"
              style={{ color: "#d4af37" }}
              onClick={async () => {
                // let listToUpdate = ApplicationState.getList();
                // let item = listToUpdate.items.filter(
                //   (x) => x.id == listItem.id
                // )[0];
                // let index = listToUpdate.items.indexOf(item);
                // if (index > -1) {
                //   listToUpdate.items.splice(index, 1);
                // }
                await TiivliAPI.deleteListItem(listItem.id);
                // let l = await TiivliAPI.getList();
                // ApplicationState.setList(l);
                history("/list");
              }}
            >
              <DeleteForever></DeleteForever>
            </Button>
          </Box>
          <Box p={1}>
            <Button
              variant="contained"
              color="primary"
              style={{ color: "#d4af37" }}
              onClick={async () => {
                ApplicationState.setListItemToEdit(listItem);
                history("/listItemCrud");
              }}
            >
              <Edit></Edit>
            </Button>
          </Box>
        </Grid>
      </Card>
    </Box>
  );
}

export default ListItemView;
