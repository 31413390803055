import { v4 as uuidv4 } from "uuid";

class TiivliAPI {
  static getApiUrl(path) {
    //return `${ApplicationState.getState().config.API_URL}/${path}`;
    return 'https://TiivliAPI.azurewebsites.net/' + path;
    //return 'https://localhost:7257/' + path;
  }

  static generateGuid() {
    return uuidv4();
  }

  static async getUser(id) {
    let result = await fetch(this.getApiUrl("user/" + id), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async getUserByEmail(email) {
    let result = await fetch(this.getApiUrl("user/ByEmail/" + email), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async getWeatherForecast() {
    let result = await fetch(this.getApiUrl("WeatherForecast/"), {
      method: "GET",
    });
    let text = await result.text();
    return text;
  }

  static async createUser(dto) {
    await this.postDto("user",dto);
  }
  
  static async updateUser(dto) {
    await this.putDto("user",dto);
  }

  static async deleteList(id) {
    await this.deleteDto("user",id);
  }

  static async getList(id) {
    let result = await fetch(this.getApiUrl("lists/" + id), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async createList(dto) {
    await this.postDto("lists",dto);
  }

  static async createListPermission(dto) {
    await this.postDto("listPermissions",dto);
  }

  static async createListItemGenre(dto) {
    await this.postDto("listItemGenres",dto);
  }
  
  static async updateList(dto) {
    await this.putDto("lists",dto);
  }

  static async deleteList(id) {
    await this.deleteDto("lists",id);
  }

  static async deleteListItemGenre(id) {
    await this.deleteDto("listItemGenres",id);
  }

  static async getListItem(id) {
    let result = await fetch(this.getApiUrl("listitems/" + id), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async getListItemsForList(id) {
    let result = await fetch(this.getApiUrl("listitems/ByListId/" + id), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async getGenresForListItem(id) {
    let result = await fetch(this.getApiUrl("listitemGenres/ForListItem/" + id), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async getListsForUser(id) {
    let result = await fetch(this.getApiUrl("lists/ForUser/" + id), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async createListItem(dto) {
    await this.postDto("listitems",dto);
  }
  
  static async updateListItem(dto) {
    await this.putDto("listitems",dto);
  }

  static async deleteListItem(id) {
    await this.deleteDto("listitems",id);
  }

  static async postDto(controller, dto){
    const response = await fetch(this.getApiUrl(controller + "/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status == "200") {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  static async putDto(controller, dto) {
    const response = await fetch(this.getApiUrl(controller + "/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status == "200") {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  static async deleteDto(controller, id) {
    let response = await fetch(this.getApiUrl(controller + "/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status == "200") {
      return "Ok";
    } else {
      console.log("Error occured for DELETE");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

}





export default TiivliAPI;
