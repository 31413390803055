class TMDBAPI {
  static getApiUrl(path) {
    //return `${ApplicationState.getState().config.API_URL}/${path}`;
    return 'https://api.themoviedb.org/3/' + path;
    
    //return 'https://localhost:7257/' + path;
  }

  static async getConfig() {
    let result = await fetch(this.getApiUrl("configuration?api_key=d8a28ca005ab7f68ffa21b4c517f24f8"), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async getMovie(queryString) {
    let result = await fetch(this.getApiUrl("search/movie?api_key=d8a28ca005ab7f68ffa21b4c517f24f8&language=sv-SE&query=" + queryString + "&page=1&include_adult=false"), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async getMovieById(id) {
    let result = await fetch(this.getApiUrl("movie/" + id + "?api_key=d8a28ca005ab7f68ffa21b4c517f24f8&language=en-US"), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async getMoviesImdbId(id) {
    let result = await fetch(this.getApiUrl("movie/" + id + "/external_ids?api_key=d8a28ca005ab7f68ffa21b4c517f24f8&language=sv-SE"), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json.imdb_id;
  }

  static async getSeries(queryString) {
    console.log(queryString);
    let result = await fetch(this.getApiUrl("search/tv?api_key=d8a28ca005ab7f68ffa21b4c517f24f8&language=sv-SE&query=" + queryString + "&page=1&include_adult=false"), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async getSeriesById(id) {
    let result = await fetch(this.getApiUrl("tv/" + id + "?api_key=d8a28ca005ab7f68ffa21b4c517f24f8&language=en-US"), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async getSeriesImdbId(id) {
    let result = await fetch(this.getApiUrl("tv/" + id + "/external_ids?api_key=d8a28ca005ab7f68ffa21b4c517f24f8&language=sv-SE"), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    return json.imdb_id;
  }
}





export default TMDBAPI;
