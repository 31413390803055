export default class ListItemDto {
    constructor(id, list, name, service, status, comment, type, createdDate, weekdayNewEpisodes, seasons, nextSeasonDate, removalDate, posterPath, tmdbId) {
      this.id = id;
      this.list = list;
      this.name = name;
      this.service = service;
      this.status = status;
      this.comment = comment;
      this.type = type;
      this.createdDate = createdDate;
      this.weekdayNewEpisodes = weekdayNewEpisodes;
      this.seasons = seasons;
      this.nextSeasonDate = nextSeasonDate;
      this.removalDate = removalDate;
      this.posterPath = posterPath;
      this.tmdbId = tmdbId;
    }
  }  