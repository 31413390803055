import React, { useEffect, useState } from "react";
import TiivliAPI from "../TiivliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  ListItemSecondaryAction,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ApplicationState from "../ApplicationState";
import UserDto from "../Dtos/UserDto";

function UserCrudView(props) {
  let history = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  // //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {

      if (ApplicationState.getUserToEditoEdit()) {
        setName(ApplicationState.getUserToEditoEdit().name);
        setEmail(ApplicationState.getUserToEditoEdit().email);
      }
    };
    load1();
  }, []);
  return (
    <Box p={2} style={{ backgroundColor: "white" }}>
      <Box p={2}>
        <Typography variant="h5">New User</Typography>
      </Box>

      <Box p={1}>
        <TextField
          id="outlined-basic"
          label="Name"
          style={{ margin: "8px" }}
          variant="outlined"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <br />
        <TextField
          id="outlined-basic"
          label="Email"
          style={{ margin: "8px" }}
          variant="outlined"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <br />
      </Box>
      <Box p={2}>
        <Button
          variant="contained"
          style={{ color: "#d4af37" }}
          onClick={async () => {
            let user = new UserDto(
              TiivliAPI.generateGuid(),
              name,
              email,
              [],
              new Date()
            );
            if (ApplicationState.getUserToEdit()) {
              user.id = ApplicationState.getUserToEdit().id;
              await TiivliAPI.updateUser(user);
            }
            if (!ApplicationState.getUserToEdit()) {
              await TiivliAPI.createUser(user);
            } 
            history("/list");
          }}
        >
          Save
        </Button>
      </Box>
      <br />
      <br />
    </Box>
  );
}

export default UserCrudView;
