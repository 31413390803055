import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import TiivliAPI from "../TiivliAPI";
// import TiivliAPI from "./TiivliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import ApplicationState from "../ApplicationState";

function LoginView(props) {
  let history = useNavigate();

  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  // //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      //Temporary add auto-login because only I and Liv are using this application now
      await ApplicationState.loginUser();
      props.doLoginFunction();
      if(ApplicationState.getLists() && ApplicationState.getLists().length > 0) {
        history("/list");
      } else{
        history("/listCrud");
      }
    };
    load1();
  }, []);
  return (
    <Box p={2} style={{ margin: "16px" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h3" style={{ color: "#d4af37" }}>
          <center>Welcome to Tiivli</center>
        </Typography>

        <Typography variant="h4">
          <i>a simple watchlist</i>
        </Typography>

        <Button
          style={{ color: "#d4af37", margin: "16px" }}
          variant="outlined"
          onClick={async () => {
            await ApplicationState.loginUser();
            props.doLoginFunction();
            if(ApplicationState.getLists() && ApplicationState.getLists().length > 0) {
              history("/list");
            } else{
              history("/listCrud");
            }
          }}
        >
          Login
        </Button>
      </Grid>
    </Box>
  );
}

export default LoginView;
