import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TiivliAPI from "../TiivliAPI";
// import TiivliAPI from "./TiivliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  AppBar,
  Card,
  CardActionArea,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DeleteForever, MovieSharp } from "@mui/icons-material";
import ApplicationState from "../ApplicationState";
import { styled } from "@mui/material/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { TabContext, TabList } from "@mui/lab";
import PlayIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import PauseIcon from "@mui/icons-material/Pause";
import ViewListIcon from "@mui/icons-material/ViewList";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterDialog from "./FilterDialog";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import { Statuses } from "../Enums";
import logoAmazonPrime from "../logo_amazonprime.webp";
import logoAppleTVPlus from "../logo_appletvplus.webp";
import logoCmore from "../logo_cmore.webp";
import logoDisneyPlus from "../logo_disneyplus.webp";
import logoHBOMax from "../logo_hbomax.webp";
import logoNetflix from "../logo_netflix.webp";
import logoSkyShowtime from "../logo_skyshowtime.webp";
import logoSvtPlay from "../logo_svtplay.webp";
import logoTv4Play from "../logo_tv4play.webp";
import logoViaplay from "../logo_viaplay.webp";

function ListView(props) {
  let history = useNavigate();

  const [list, setList] = useState([]);
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState(Statuses.Ready);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  const CustomTab = styled(Tab)({
    textTransform: "capitalize",
    color: "#ddd",
  });

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      setLoading(true);
      if (ApplicationState.getList()) {
        console.log(ApplicationState.getList());
        let l = await TiivliAPI.getList(ApplicationState.getList().id);
        console.log(l);
        setList(l);
        let i = await TiivliAPI.getListItemsForList(
          ApplicationState.getList().id
        );
        console.log(i);
        setItems(i);
      }
      let statusesToAdd = [];
      statusesToAdd.push("Ready");
      statusesToAdd.push("Watching");
      statusesToAdd.push("Paused");
      statusesToAdd.push("Finished");
      setStatuses(statusesToAdd);
      setStatus(statusesToAdd[0]);
      setLoading(false);
    };
    load1();
  }, []);

  useEffect(() => {
    const load1 = async () => {
      await loadListAndItems();
    };
    load1();
  }, [props.changedList]);

  async function loadListAndItems(){
    setLoading(true);
      if (ApplicationState.getList()) {
        let l = await TiivliAPI.getList(ApplicationState.getList().id);
        setList(l);
        let i = await TiivliAPI.getListItemsForList(
          ApplicationState.getList().id
        );
        i = i.sort((a, b) => { return a.name.localeCompare(b.name); });
        setItems(i);
      }
      let statusesToAdd = [];
      statusesToAdd.push("Ready");
      statusesToAdd.push("Watching");
      statusesToAdd.push("Paused");
      statusesToAdd.push("Finished");
      setStatuses(statusesToAdd);
      setStatus(statusesToAdd[0]);
      setLoading(false);
  }

  function addStatus(status) {
    return <option key={status} label={status} value={status}></option>;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    // setSelectedValue(value);
  };

  return (
    <Box p={0}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        {list && (
          <React.Fragment>
            {/* <Box>
              <Typography variant="h5">{list.name}</Typography>
            </Box> */}
          </React.Fragment>
        )}
      </Grid>

      <Box style={{ backgroundColor: "#ddd", margin: "0px", marginTop: "0px" }}>
        {!loading && (
          <React.Fragment>
            <Box
              style={{
                backgroundColor: "#252525",
                margin: "0px",
                marginBottom: "0px",
                padding: "16px",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ color: "#d4af37" }}
                    onClick={async () => {
                      ApplicationState.setListItemToEdit(undefined);
                      history("/listItemCrud");
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Box>Add</Box>
                      <Box style={{ paddingLeft: "6px", paddingTop: "6px" }}>
                        <LocalMoviesIcon />
                      </Box>
                    </Grid>
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    onClick={handleClickOpen}
                    color="secondary"
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Box>Filter</Box>
                      <Box style={{ paddingLeft: "6px", paddingTop: "6px" }}>
                        <FilterListIcon />
                      </Box>
                    </Grid>
                  </Button>
                </Box>
              </Grid>
            </Box>
            <Divider
              style={{ color: "red", backgroundColor: "#d4af37" }}
            ></Divider>
            <TabContext value={value}>
              <AppBar position="static">
                <TabList
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  style={{ backgroundColor: "#252525" }}
                  variant="fullWidth"
                  textColor="secondary"
                  indicatorColor="secondary"
                >
                  <CustomTab
                    icon={<ViewListIcon />}
                    label="READY"
                    value="Ready"
                  />
                  <CustomTab
                    icon={<PlayIcon />}
                    label="WATCHING"
                    value="Watching"
                  />
                  <CustomTab
                    icon={<PauseIcon />}
                    label="PAUSED"
                    value="Paused"
                  />
                  <CustomTab
                    icon={<StopIcon />}
                    label="FINISHED"
                    value="Finished"
                  />
                </TabList>
              </AppBar>
            </TabContext>
            <Box style={{ backgroundColor: "#252525", padding: "1px" }}></Box>
            <List
              style={{
                backgroundColor: "white",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              {items
                .filter((x) => x.status == value)
                .map((element) => {
                  return (
                    <React.Fragment>
                      <ListItem
                        key={element.id}
                        button
                        onClick={() => {
                          history("/listItem/" + element.id);
                        }}
                      >
                        {/* <CardActionArea
                      onClick={() => {
                        history("/listItem/" + element.id);
                      }}
                    > */}
                    
                      <ListItemAvatar style={{marginRight: "16px"}}>
                        {element.posterPath && (
                          <img src={"http://image.tmdb.org/t/p/" + "w92" + element.posterPath} />
                        )}
                        {!element.posterPath && (
                          <MovieSharp style={{fontSize: "xx-large", width: "92px"}} />
                        )}
                      </ListItemAvatar>
                        <ListItemText>
                          <Typography variant="h5">{element.name}</Typography>
                          {element.status == Statuses.Ready && element.comment && element.comment.length > 0 && (
                            <Typography variant="subtitle1" >{element.comment}</Typography>
                          )}
                          {element.service == "Netflix" && ( 
                              <img src={logoNetflix} width="32px" style={{borderRadius: "4px", marginTop: "12px"}} />
                          )}
                          {element.service == "Amazon Prime" && (
                              <img src={logoAmazonPrime} width="32px" style={{borderRadius: "4px", marginTop: "12px"}} />
                          )}
                          {element.service == "Disney+" && (
                              <img src={logoDisneyPlus} width="32px" style={{borderRadius: "4px", marginTop: "12px"}} />
                          )}
                          {element.service == "HBO Max" && (
                              <img src={logoHBOMax} width="32px" style={{borderRadius: "4px", marginTop: "12px"}} />
                          )}
                          {element.service == "Apple TV+" && (
                              <img src={logoAppleTVPlus} width="32px" style={{borderRadius: "4px", marginTop: "12px"}} />
                          )}
                          {element.service == "Cmore" && (
                            <img src={logoCmore} width="32px" style={{borderRadius: "4px", marginTop: "12px"}} />
                          )}
                          {element.service == "Sky Showtime" && (
                            <img src={logoSkyShowtime} width="32px" style={{borderRadius: "4px", marginTop: "12px"}} />
                          )}
                          {element.service == "SVT Play" && (
                            <img src={logoSvtPlay} width="32px" style={{borderRadius: "4px", marginTop: "12px"}} />
                          )}
                          {element.service == "TV4 Play" && (
                            <img src={logoTv4Play} width="32px" style={{borderRadius: "4px", marginTop: "12px"}} />
                          )}
                          {element.service == "Viaplay" && (
                            <img src={logoViaplay} width="32px" style={{borderRadius: "4px", marginTop: "12px"}} />
                          )}
                          {element.weekdayNewEpisodes && element.weekdayNewEpisodes.length > 0 && (
                            <Typography variant="subtitle2">
                              {"New episodes on "}{element.weekdayNewEpisodes}
                            </Typography>
                          )}
                          {element.status == Statuses.Paused && element.nextSeasonDate && (
                            <i>{new Date(element.nextSeasonDate).toLocaleDateString("sv-SE")}</i>
                          )}
                          {element.status == Statuses.Finished && (
                            <React.Fragment>
                            <i>{element.liked}</i>
                            </React.Fragment>
                          )}
                          
                        </ListItemText>
                        {/* </CardActionArea> */}
                        {/* <Button
                      variant="contained"
                      style={{ color: "#d4af37" }}
                      onClick={async () => {
                        let listToUpdate = ApplicationState.getList();
                        let item = listToUpdate.items.filter(
                          (x) => x.id == element.id
                        )[0];
                        let index = listToUpdate.items.indexOf(item);
                        if (index > -1) {
                          listToUpdate.items.splice(index, 1);
                        }
                        await TiivliAPI.updateList(listToUpdate);
                        let l = await TiivliAPI.getList();
                        setList(l);
                        ApplicationState.setList(l);
                      }}
                    >
                      <DeleteForever></DeleteForever>
                    </Button> */}
                      </ListItem>
                      <Divider></Divider>
                    </React.Fragment>
                  );
                })}
              {items.filter((x) => x.status == value).length < 1 && (
                <Box>No items</Box>
              )}
            </List>
          </React.Fragment>
        )}
        {loading && (
          <Box p={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ paddingBottom: "8px" }}
            >
              <CircularProgress></CircularProgress>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="body1">Loading...</Typography>
            </Grid>
          </Box>
        )}
      </Box>
      <Box></Box>
      <FilterDialog
        // selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </Box>
  );
}

export default ListView;
