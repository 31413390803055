import TiivliAPI from "./TiivliAPI";

export const StateKey = "State";

export default class ApplicationState {
  static storeState(state) {
    sessionStorage.setItem(StateKey, JSON.stringify(state));
  }

  static hasState() {
    let state = sessionStorage.getItem(StateKey);
    return state !== undefined && state !== null;
  }

  static getState() {
    let value = sessionStorage.getItem(StateKey);
    if (value === undefined || value === null) {
      throw Error("Failed to load state from session storage");
    }
    try {
      var object = JSON.parse(value);
      return object;
    } catch (e) {
      throw Error("Failed to parse stored state: " + e);
    }
  }

  static clearState() {
    sessionStorage.setItem(StateKey, JSON.stringify({}));
  }

  static getList() {
    return this.getState().list;
  }

  static setList(list) {
    let state = this.getState();
    state.list = list;
    this.storeState(state);
  }

  static getLists() {
    return this.getState().lists;
  }

  static setLists(lists) {
    let state = this.getState();
    state.lists = lists;
    this.storeState(state);
  }

  static getListItemToEdit() {
    return this.getState().listItemToEdit;
  }

  static setListItemToEdit(listItemToEdit) {
    let state = this.getState();
    state.listItemToEdit = listItemToEdit;
    this.storeState(state);
  }

  static getListToEdit() {
    return this.getState().listToEdit;
  }

  static setListToEdit(listToEdit) {
    let state = this.getState();
    state.listToEdit = listToEdit;
    this.storeState(state);
  }

  static getUserToEdit() {
    return this.getState().userToEdit;
  }

  static setUserToEdit(userToEdit) {
    let state = this.getState();
    state.userToEdit = userToEdit;
    this.storeState(state);
  }

  static getUser() {
    return this.getState().user;
  }

  static setUser(user) {
    let state = this.getState();
    state.user = user;
    this.storeState(state);
  }

  static viewMenuItem() {
    return true;
  }

  static isAdmin() {
    return true;
  }

  static async loginUser() {
    console.log("LoginUser called");
    let email = "avalin87@gmail.com";
    let user = await TiivliAPI.getUserByEmail(email);
    ApplicationState.setUser(user);
    let lists = [];
    this.setLists(lists);
    lists = await TiivliAPI.getListsForUser(user.id);
    this.setLists(lists);
    if(lists && lists.length > 0){
      this.setList(lists[0]);
    }
    // if(user.listIds && user.listIds.length > 0) {
    //   await Promise.all(user.listIds.map(async (element) => {
    //     let list = await TiivliAPI.getList(element);  
    //     lists.push(list);
        
    //   }));
    //   this.setLists(lists);
    //   if(lists && lists.length > 0){
    //     this.setList(lists[0]);
    //   }
    // }
  }

  static logout() {
    this.clearState();
  }
}
