import { Box, Button, Dialog, DialogTitle, Divider, InputLabel, Select } from "@mui/material";
import ApplicationState from "../ApplicationState";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

const InputLabelSelect = styled(InputLabel)({
  paddingLeft: "8px",
  position: "relative",
  backgroundColor: "#f7f7f7",
  marginLeft: "4px",
  top: "32px",
  width: "60px",
});

function FilterDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [service, setService] = useState("");
  const [status, setStatus] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    const load1 = async () => {
      let servicesToAdd = [];
      servicesToAdd.push("All");
      servicesToAdd.push("Netflix");
      servicesToAdd.push("Amazon Prime");
      servicesToAdd.push("Cmore");
      servicesToAdd.push("HBO Max");
      servicesToAdd.push("SVT Play");
      servicesToAdd.push("TV4 Play");
      servicesToAdd.push("Viaplay");
      setServices(servicesToAdd);
      setService(servicesToAdd[0]);
    };
    load1();
  }, []);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  function addService(service) {
    return <option key={service} label={service} value={service}></option>;
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Filter</DialogTitle>
      <Divider></Divider>
      <Box m={1}>
        <InputLabelSelect
          id="serviceSelectLabel"
          htmlFor="serviceSelect"
          variant="outlined"
          shrink={true}
        >
          Service
        </InputLabelSelect>
        <Select
          native
          onChange={(event) => {
            setService(event.target.value);
            if (event.target.value != "All") {
              let l = ApplicationState.getList();
              l.items = l.items.filter((x) => x.service == event.target.value);
              // setList(l);
            }
            if (event.target.value == "All") {
              let l = ApplicationState.getList();
              // setList(l);
            }
          }}
          id="serviceSelect"
          variant="outlined"
          sx={{
            margin: "8px",
            fontWeight: "500",
            width: { xs: "95%", sm: "300px" },
          }}
          value={service}
        >
          {services
            .sort((a, b) => (a > b ? 1 : -1))
            .map((service) => {
              return addService(service);
            })}
          {/* <option aria-label="None" value="" /> */}
        </Select>
        <Divider></Divider>
        <Box p={1} m={0}>
        <Button variant="contained"
          style={{ color: "#d4af37" }}>Add filter</Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default FilterDialog;
