import React, { useEffect, useState } from "react";
import TiivliAPI from "../TiivliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  ListItemSecondaryAction,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import ListItemDto from "../Dtos/ListItemDto";
import ApplicationState from "../ApplicationState";
import { styled } from "@mui/material/styles";
import ListItemGenreDto from "../Dtos/ListItemGenreDto";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TMDBAPI from "../TMDBAPI";
import logoCmore from "../logo_cmore.webp";

const InputLabelSelect = styled(InputLabel)({
  paddingLeft: "8px",
  position: "relative",
  backgroundColor: "#fff",
  marginLeft: "4px",
  top: "32px",
  width: "175px",
});

const InputLabelSelectServiceStatus = styled(InputLabel)({
  paddingLeft: "8px",
  position: "relative",
  backgroundColor: "#fff",
  marginLeft: "4px",
  top: "32px",
  width: "60px",
});

function ListItemCrudView(props) {
  let history = useNavigate();

  const [name, setName] = useState("");
  const [service, setService] = useState("");
  const [services, setServices] = useState([]);
  const [status, setStatus] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [comment, setComment] = useState("");
  const [type, setType] = useState("Series");
  const [weekdayNewEpisodes, setWeekdayNewEpisodes] = useState("");
  const [seasons, setSeasons] = useState("");
  const [genres, setGenres] = useState([]);
  const [nextSeasonDate, setNextSeasonDate] = useState(undefined);
  const [removalDate, setRemovalDate] = useState(undefined);

  const [genreAction, setGenreAction] = useState(false);
  const [genreComedy, setGenreComedy] = useState(false);
  const [genreDrama, setGenreDrama] = useState(false);
  const [genreFantasy, setGenreFantasy] = useState(false);
  const [genreHorror, setGenreHorror] = useState(false);
  const [genreMystery, setGenreMystery] = useState(false);
  const [genreRomance, setGenreRomance] = useState(false);
  const [genreThriller, setGenreThriller] = useState(false);


  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [posterPath, setPosterPath] = React.useState(undefined);
  const [tmdbId, setTmdbId] = React.useState(undefined);

  // //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      let statusesToAdd = [];
      statusesToAdd.push("Ready");
      statusesToAdd.push("Watching");
      statusesToAdd.push("Paused");
      statusesToAdd.push("Finished");
      setStatuses(statusesToAdd);
      setStatus(statusesToAdd[0]);

      let servicesToAdd = [];
      
      servicesToAdd.push("?");
      servicesToAdd.push("Amazon Prime");
      servicesToAdd.push("Apple TV+");
      servicesToAdd.push("Cmore");
      servicesToAdd.push("Disney+");
      servicesToAdd.push("HBO Max");
      servicesToAdd.push("Netflix");
      servicesToAdd.push("SkyShowtime");
      servicesToAdd.push("SVT Play");
      servicesToAdd.push("TV4 Play");
      servicesToAdd.push("Viaplay");
      
      setServices(servicesToAdd);
      setService(servicesToAdd[0]);

      if (ApplicationState.getListItemToEdit()) {
        let existingGenres = await TiivliAPI.getGenresForListItem(ApplicationState.getListItemToEdit().id);
        setName(ApplicationState.getListItemToEdit().name);
        setInputValue(ApplicationState.getListItemToEdit().name);
        setService(ApplicationState.getListItemToEdit().service);
        setStatus(ApplicationState.getListItemToEdit().status);
        setComment(ApplicationState.getListItemToEdit().comment);
        setType(ApplicationState.getListItemToEdit().type);
        setSeasons(ApplicationState.getListItemToEdit().seasons);
        setPosterPath(ApplicationState.getListItemToEdit().posterPath);
        setTmdbId(ApplicationState.getListItemToEdit().tmdbId);
        if (ApplicationState.getListItemToEdit().nextSeasonDate) {
          setNextSeasonDate(
            new Date(
              ApplicationState.getListItemToEdit().nextSeasonDate
            ).toLocaleDateString("sv-SE")
          );
        }
        if (ApplicationState.getListItemToEdit().removalDate) {
          setRemovalDate(
            new Date(
              ApplicationState.getListItemToEdit().removalDate
            ).toLocaleDateString("sv-SE")
          );
        }
        setWeekdayNewEpisodes(
          ApplicationState.getListItemToEdit().weekdayNewEpisodes
        );

        if (
          existingGenres.some((x) => x.name == "Action")
        ) {
          setGenreAction(true);
        }
        if (
          existingGenres.some((x) => x.name == "Comedy")
        ) {
          setGenreComedy(true);
        }
        if (
          existingGenres.some((x) => x.name == "Drama")
        ) {
          setGenreDrama(true);
        }
        if (
          existingGenres.some(
            (x) => x.name == "Fantasy"
          )
        ) {
          setGenreFantasy(true);
        }
        if (
          existingGenres.some((x) => x.name == "Horror")
        ) {
          setGenreHorror(true);
        }
        if (
          existingGenres.some(
            (x) => x.name == "Mystery"
          )
        ) {
          setGenreMystery(true);
        }
        if (
          existingGenres.some(
            (x) => x.name == "Romance"
          )
        ) {
          setGenreRomance(true);
        }
        if (
          existingGenres.some(
            (x) => x.name == "Thriller"
          )
        ) {
          setGenreThriller(true);
        }
      }
    };
    load1();
  }, []);

  useEffect(() => {
    const load1 = async () => {
      if(inputValue && inputValue.length > 0 && type == "Movie") {
        let movie = await TMDBAPI.getMovie(inputValue);
        setOptions(movie.results);
      }
      if(inputValue && inputValue.length > 0 && type == "Series") {
        let series = await TMDBAPI.getSeries(inputValue);
        setOptions(series.results);
      }
    };
    load1();
  }, [name, inputValue]);

  function addGenres() {
    return (
      // <Box>
      // <CheckBox defaultChecked color="primary" checked={true} onChange={(event) => {
      //  method(event.target.checked);
      // }} />
      // sasaas
      // </Box>
      <FormGroup style={{ padding: "8px" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={genreAction}
              onChange={() => setGenreAction(!genreAction)}
            />
          }
          label="Action"
          style={{ padding: "8px" }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={genreComedy}
              onChange={() => setGenreComedy(!genreComedy)}
            />
          }
          label="Comedy"
          style={{ padding: "8px" }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={genreDrama}
              onChange={() => setGenreDrama(!genreDrama)}
            />
          }
          label="Drama"
          style={{ padding: "8px" }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={genreFantasy}
              onChange={() => setGenreFantasy(!genreFantasy)}
            />
          }
          label="Fantasy"
          style={{ padding: "8px" }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={genreHorror}
              onChange={() => setGenreHorror(!genreHorror)}
            />
          }
          label="Horror"
          style={{ padding: "8px" }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={genreMystery}
              onChange={() => setGenreMystery(!genreMystery)}
            />
          }
          label="Mystery"
          style={{ padding: "8px" }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={genreRomance}
              onChange={() => setGenreRomance(!genreRomance)}
            />
          }
          label="Romance"
          style={{ padding: "8px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={genreThriller}
              onChange={() => setGenreThriller(!genreThriller)}
            />
          }
          label="Thriller"
          style={{ padding: "8px" }}
        />
        {/* <FormControlLabel control={<CheckBox />} label="Comedy" style={{padding:"8px"}} />
    <FormControlLabel control={<CheckBox />} label="Drama" style={{padding:"8px"}} />
    <FormControlLabel control={<CheckBox />} label="Fantasy" style={{padding:"8px"}} />
    <FormControlLabel control={<CheckBox />} label="Horror" style={{padding:"8px"}} />
    <FormControlLabel control={<CheckBox />} label="Mystery" style={{padding:"8px"}} />
    <FormControlLabel control={<CheckBox />} label="Romance" style={{padding:"8px"}} />
    <FormControlLabel control={<CheckBox />} label="Thriller" style={{padding:"8px"}} /> */}
      </FormGroup>
    );
  }

  function addWeekdayNewEpisodes() {
    return (
      <React.Fragment>
        <InputLabelSelect
          id="weekdayNewEpisodesSelectLabel"
          htmlFor="weekdayNEwEpisodesSelect"
          variant="outlined"
          shrink={true}
        >
          Weekday new episodes
        </InputLabelSelect>
        <Select
          native
          onChange={(event) => {
            setWeekdayNewEpisodes(event.target.value);
          }}
          id="weekdayNewEpisodesSelect"
          variant="outlined"
          sx={{
            margin: "8px",
            fontWeight: "500",
            width: { xs: "300px" },
          }}
          value={weekdayNewEpisodes}
        >
          <option aria-label="-" value="" />
          <option key={"Mondays"} label={"Mondays"} value={"Mondays"}></option>;
          <option
            key={"Tuesdays"}
            label={"Tuesdays"}
            value={"Tuesdays"}
          ></option>
          ;
          <option
            key={"Wedesdays"}
            label={"Wedesdays"}
            value={"Wedesdays"}
          ></option>
          ;
          <option
            key={"Thursdays"}
            label={"Thursdays"}
            value={"Thursdays"}
          ></option>
          ;<option key={"Fridays"} label={"Fridays"} value={"Fridays"}></option>
          ;
          <option
            key={"Saturdays"}
            label={"Saturdays"}
            value={"Saturdays"}
          ></option>
          ;<option key={"Sundays"} label={"Sundays"} value={"Sundays"}></option>
          ;
        </Select>
      </React.Fragment>
    );
  }

  function addServicesSelect() {
    return (
      <Box>
        <InputLabelSelectServiceStatus
          id="serviceselectLabel"
          htmlFor="servicesSelect"
          variant="outlined"
          shrink={true}
        >
          Service
        </InputLabelSelectServiceStatus>
        <Select
          native
          onChange={(event) => {
            setService(event.target.value);
          }}
          id="serviceSelect"
          variant="outlined"
          sx={{
            margin: "8px",
            fontWeight: "500",
            width: { xs: "300px" },
          }}
          value={service}
        >
          {/* <option aria-label="?" label="?" value="?" /> */}
          {services.map((element) => {
            return(<option key={element} value={element}>{element}</option>);
          })}
          
        </Select>

        
      </Box>
    );
  }

  function addStatusesSelect() {
    return (
      <Box>
        <InputLabelSelectServiceStatus
          id="statusesSelectLabel"
          htmlFor="statusesSelect"
          variant="outlined"
          shrink={true}
        >
          Status
        </InputLabelSelectServiceStatus>
        <Select
          native
          onChange={(event) => {
            setStatus(event.target.value);
          }}
          id="statusesSelect"
          variant="outlined"
          sx={{
            margin: "8px",
            fontWeight: "500",
            width: { xs: "300px" },
          }}
          value={status}
        >
          {statuses.map((element) => {
            return(<option key={element} label={element} value={element}></option>);

          })}
          
        </Select>
      </Box>
    );
  }

  return (
    <Box p={2} style={{ backgroundColor: "white" }}>
      <Box p={2}>
        <Typography variant="h5">New Series/Movie</Typography>
      </Box>

      <Box p={1}>
      <Box style={{margin: "8px"}}>
          <FormControl component="fieldset">
            {/* <FormLabel component="legend">Type</FormLabel> */}
            <RadioGroup
              aria-label="type for series/movie"
              name="Type"
              value={type}
              onChange={(event) => setType(event.target.value)}
            >
              <FormControlLabel
                value="Series"
                control={<Radio color="primary" />}
                label="Series"
              />
              <FormControlLabel
                value="Movie"
                control={<Radio color="primary" />}
                label="Movie"
              />
            </RadioGroup>
          </FormControl>
        </Box>



      <Autocomplete
      id="google-map-demo"
      sx={{
        width: 300,
        margin: "8px",
        marginTop: "16px",
        marginBottom: "8px",
      }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={name}
      noOptionsText="No movie/series"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if(type == "Movie") {
          setName(newValue.title);
        } if (type == "Series") { 
          setName(newValue.name);
        }
        if(newValue.poster_path && newValue.poster_path.length > 0) {
          setPosterPath(newValue.poster_path);
        }
        if(newValue.id) {
          setTmdbId(newValue.id);
          console.log("HASTAR22");
        }
        console.log("HASTAR");
        console.log(newValue.id);
        console.log(newValue.poster_path);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        console.log("you clicked");
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search movie/series" fullWidth style={{width: "300px"}} />
      )}
      renderOption={(props, option) => {
        // const matches =
        //   option.structured_formatting.main_text_matched_substrings || [];

        // const parts = parse(
        //   option.structured_formatting.main_text,
        //   matches.map((match) => [match.offset, match.offset + match.length]),
        // );

        

        let primaryText = "";
        let secondaryText = "";
        if(type == "Movie"){
          primaryText = option.title;
          if(option.release_date){
            secondaryText = option.release_date.substring(0, 4) + " " + option.original_language.toUpperCase();
          } else{
            secondaryText = "?";
          }
          
        }
        if(type == "Series"){
          primaryText = option.name;
          if(option.first_air_date){
            secondaryText = option.first_air_date.substring(0, 4) + " - " + option.original_language.toUpperCase();
          } else{
            secondaryText = "?";
          }
          
        }

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                {/* <LocationOnIcon sx={{ color: 'text.secondary' }} /> */}
                <img width="30px" src={"http://image.tmdb.org/t/p/" + "w92" + option.poster_path} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {/* {options.map((part, index) => ( */}
                  <Box
                    key={option.id}
                    component="span"
                    sx={{ fontWeight: 'bold' }}
                  >
                    {primaryText}
                  </Box>
                {/* ))} */}

                <Typography variant="body2" color="text.secondary">
                  {secondaryText}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />




        <TextField
          id="outlined-basic"
          label="Name"
          style={{ margin: "8px", display: "none", visibility: "hidden" }}
          variant="outlined"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        
        <TextField
          id="outlined-basic"
          label="Comment"
          multiline
          rows={3}
          style={{ margin: "8px", width: "300px" }}
          variant="outlined"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
        {/* 
        <TextField
          id="outlined-basic"
          label="Status"
          style={{ margin: "8px" }}
          variant="outlined"
          value={status}
          onChange={(event) => setStatus(event.target.value)}
        />
        <br /> */}
         {/* 
        <Autocomplete
          id="services"
          sx={{
            width: 300,
            margin: "8px",
            marginTop: "16px",
            marginBottom: "24px",
          }}
          options={services}
          value={service}
          autoHighlight
          onChange={(event, newValue) => {
            setService(newValue);
          }}
          getOptionLabel={(option) => option}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box>
                    <b>{option}</b>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Service"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />*/}
        {addServicesSelect()}

        {/* <Autocomplete
          id="statuses"
          sx={{
            width: 300,
            margin: "8px",
            marginTop: "24px",
            marginBottom: "16px",
          }}
          options={statuses}
          value={status}
          autoHighlight
          onChange={(event, newValue) => {
            setStatus(newValue);
          }}
          getOptionLabel={(option) => option}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box>
                    <b>{option}</b>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Status"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        /> */}
        {addStatusesSelect()}
        {/* <TextField
          id="outlined-basic"
          label="Seasons"
          style={{ margin: "8px" }}
          variant="outlined"
          value={seasons}
          onChange={(event) => setSeasons(event.target.value)}
        />
        <br /> */}
        
        <TextField
          id="nextSeasonDateTextField"
          type="date"
          label="Next season date"
          style={{ margin: "8px", minWidth: "300px" }}
          onChange={(event) => setNextSeasonDate(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={nextSeasonDate}
        />
        <br />
        <TextField
          id="removalDateTextField"
          type="date"
          label="Removal date"
          style={{ margin: "8px", marginBottom: "0px", minWidth: "300px" }}
          onChange={(event) => setRemovalDate(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={removalDate}
        />
        
        {addWeekdayNewEpisodes()}
        {/* {addGenres()} */}
      </Box>
      <Box p={2}>
        <Button
          variant="contained"
          style={{ color: "#d4af37" }}
          onClick={async () => {
            // let updatedList = ApplicationState.getList();
            let selectedGenres = [];
            if (genreAction) {
              selectedGenres.push("Action");
            }
            if (genreDrama) {
              selectedGenres.push("Drama");
            }

            if (genreFantasy) {
              selectedGenres.push("Fantasy");
            }
            if (genreHorror) {
              selectedGenres.push("Horror");
            }
            if (genreComedy) {
              selectedGenres.push("Comedy");
            }
            if (genreMystery) {
              selectedGenres.push("Mystery");
            }
            if (genreRomance) {
              selectedGenres.push("Romance");
            }
            if (genreThriller) {
              selectedGenres.push("Thriller");
            }

            let nextSeasonDateForSave = undefined;
            if (nextSeasonDate) {
              nextSeasonDateForSave = new Date(
                nextSeasonDate
              ).toLocaleDateString("sv-SE");
            }

            let removalDateForSave = undefined;
            if (removalDate) {
              removalDateForSave = new Date(removalDate).toLocaleDateString(
                "sv-SE"
              );
            }

            let seasonsForSave = 0;
            if (seasons && seasons.length > 0) {
              seasonsForSave = seasons;
            }
            //TODO make this better
            if(!posterPath || posterPath.length <= 0) {
              let tmdbItem = undefined;
              if(type == "Movie") {
                tmdbItem = await TMDBAPI.getMovie(inputValue);
              }
              if(type == "Series") {
                tmdbItem = await TMDBAPI.getSeries(inputValue);
              }
              if(tmdbItem && tmdbItem.results && tmdbItem.results.length > 0) {
                setPosterPath(tmdbItem.results[0].poster_path);
              }
            }
            if(inputValue && inputValue.length > 0 && type == "Series") {
              let series = await TMDBAPI.getSeries(inputValue);
              setOptions(series.results);
            }
            if(inputValue && inputValue.length > 0 && type == "Movie") {
              let movies = await TMDBAPI.getMovie(inputValue);
              setOptions(movies.results);
            }

            let item = new ListItemDto(
              TiivliAPI.generateGuid(),
              ApplicationState.getList(),
              name,
              service,
              status,
              comment,
              type,
              new Date(),
              weekdayNewEpisodes,
              seasonsForSave,
              nextSeasonDateForSave,
              removalDateForSave,
              posterPath,
              tmdbId
            );
            console.log("SAVE");
            console.log(item);
            console.log("SAVE");
            if (ApplicationState.getListItemToEdit()) {
              item.id = ApplicationState.getListItemToEdit().id;
              await TiivliAPI.updateListItem(item);
              let existingGenres = await TiivliAPI.getGenresForListItem(
                item.id
              );
              await Promise.all(
                existingGenres.map(async (element) => {
                  await TiivliAPI.deleteListItemGenre(element.id);
                })
              );

              // let existingItem = updatedList.items.filter(x => x.id == item.id)[0];
              // let index = updatedList.items.indexOf(existingItem);
              // if (index > -1) {
              //   updatedList.items.splice(index, 1);
              // }
              // updatedList.items.push(item);
            }
            if (!ApplicationState.getListItemToEdit()) {
              await TiivliAPI.createListItem(item);
              // await TiivliAPI.getGenresForListItem(item.id);
              // await TiivliAPI.deleteListItemGenresForListItem();
            }
            await Promise.all(
              selectedGenres.map(async (element) => {
                await TiivliAPI.createListItemGenre(
                  new ListItemGenreDto(
                    TiivliAPI.generateGuid(),
                    element,
                    item
                  )
                );
              })
            );

            // ApplicationState.setList(updatedList);
            ApplicationState.setListItemToEdit(undefined);
            history("/list");
          }}
        >
          Save
        </Button>
      </Box>
      <br />
      <br />
    </Box>
  );
}

export default ListItemCrudView;
