import React, { useEffect, useState } from "react";
import TiivliAPI from "../TiivliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  ListItemSecondaryAction,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ApplicationState from "../ApplicationState";
import UserDto from "../Dtos/UserDto";
import ListDto from "../Dtos/ListDto";
import ListPermissionDto from "../Dtos/ListPermissionDto";

function ListCrudView(props) {
  let history = useNavigate();
  const [name, setName] = useState("");

  // //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      if (ApplicationState.getListToEdit()) {
        setName(ApplicationState.getListToEdit().name);
      }
    };
    load1();
  }, []);
  return (
    <Box p={2} style={{ backgroundColor: "white" }}>
      <Box p={2}>
        <Typography variant="h5">New List</Typography>
      </Box>

      <Box p={1}>
        <TextField
          id="outlined-basic"
          label="Name"
          style={{ margin: "8px" }}
          variant="outlined"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <br />
      </Box>
      <Box p={2}>
        <Button
          variant="contained"
          style={{ color: "#d4af37" }}
          onClick={async () => {
            let list = new ListDto(
              TiivliAPI.generateGuid(),
              name,
              new Date()
            );
            if (ApplicationState.getListToEdit()) {
              list.id = ApplicationState.getListToEdit().id;
              await TiivliAPI.updateList(list);
              let updatedList = await TiivliAPI.getList(list.id);
              ApplicationState.setList(updatedList);
            }
            else if (!ApplicationState.getListToEdit()) {
              await TiivliAPI.createList(list);
              await TiivliAPI.createListPermission(new ListPermissionDto(TiivliAPI.generateGuid(), ApplicationState.getUser(), list, "Owner", new Date()));
              let lists = await TiivliAPI.getListsForUser(ApplicationState.getUser().id);
              ApplicationState.setLists(lists);
              ApplicationState.setList(list);
              //TODO: LÄgg till något liknande dologin-function för att få till en refresh av menyn
            }
            ApplicationState.setListToEdit(undefined); 
            history("/list");
          }}
        >
          Save
        </Button>
      </Box>
      <br />
      <br />
    </Box>
  );
}

export default ListCrudView;
