import React, { useEffect } from "react";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import logo from "../logo.svg";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AppsIcon from "@mui/icons-material/Apps";
import HomeIcon from "@mui/icons-material/Home";
import Hidden from "@mui/material/Hidden";
import LoginView from "./LoginView";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import ApplicationState from "../ApplicationState";
import Grid from "@mui/material/Grid";
import SettingsIcon from "@mui/icons-material/Settings";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { BarChart } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ListView from "./ListView";
import ListItemCrudView from "./ListItemCrudView";
import ListItemView from "./ListItemView";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import ListIcon from "@mui/icons-material/List";
import UserCrudView from "./UserCrudView";
import ListCrudView from "./ListCrudView";
import { Menu, MenuItem } from "@mui/material";
import TiivliAPI from "../TiivliAPI";

const MenuList = styled(List)({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "red",
    backgroundColor: "#252525 !important",
    // backgroundAttachment: "fixed",
    // backgroundRepeat: "no-repeat",
    // backgroundImage: "linear-gradient(135deg, #282428 0%, #111 100%) ",
    // color: "#fff",
    // '&, & .MuiListItemIcon-root': {
    //   color: 'pink',
    // },
  },
});

const drawerWidth = 240;

export default function TopBar(props) {
  const [, setHasLoggedIn] = React.useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = React.useState("Home");

  const { window2 } = props;
  const theme = useTheme();
  const [showMenuText, setShowMenuText] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const container =
    window2 !== undefined ? () => window().document.body : undefined;
  const openListMenu = Boolean(anchorEl2);
  

  useEffect(() => {
    const load1 = async () => {
      // setLoading(true);
      // await ApplicationState.loginUser();
      // setLoading(false);
    };
    load1();
  }, []);

function closeListMenu(){
  setAnchorEl2(null);
}

  function doLogin() {
    setHasLoggedIn(true);
  }

  function logout() {
    ApplicationState.logout();
    window.location.href = "/";
    setHasLoggedIn(false);
    handleCloseUserMenu();
  }

  function handleCloseUserMenu() {
    setAnchorEl(null);
  }

  const drawer = (anchor) => (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      style={{ backgroundColor: "#252525", color: "#d4af37" }}
    >
      <Grid item>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ justifyContent: "center", padding: "24px" }}
        >
          <React.Fragment>
            <NavLink
              to="/list"
              onClick={() => {
                setMobileOpen(false);
                setSelectedMenuItem("Home");
              }}
            >
              {/* <img src={logo} style={{height: "50px",
                                width: "155px",
                                margin: "8px 8px 8px 4px",
                                top: "57px",
                                left: "58px",
                                alignContent: ""}} /> */}
              <Typography
                variant="h5"
                style={{ backgroundColor: "#252525", color: "#d4af37" }}
              >
                Tiivli
              </Typography>
            </NavLink>
            <br />
            <br />
            <Button
              component={NavLink}
              to="/listCrud"
              variant="outlined"
              style={{ color: "#d4af37" }}
              onClick={async () => {
                ApplicationState.setListToEdit(undefined);
                setMobileOpen(false);
              }}
            >
              Add list
            </Button>
          </React.Fragment>
        </Grid>

        {/* <Divider style={{borderColor: "#f6f6f6"}} /> */}
        <Box p={1}></Box>
        <Divider style={{ borderColor: "#d4af37" }} />

        <Box>
          <MenuList style={{ padding: "0px" }}>
            {ApplicationState.viewMenuItem() && (
              <Box>
                <ListItem
                  button
                  component={NavLink}
                  to="/list"
                  // onClick={() => {
                  //   setMobileOpen(false);
                  //   setSelectedMenuItem("Home");
                  // }}
                  // selected={selectedMenuItem === "Home"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <VideoLibraryIcon style={{ color: "#fff" }} />
                  </ListItemIcon>
                  <ListItemText primary="My Lists" />
                </ListItem>
                <Divider style={{ borderColor: "#d4af37" }} />
                {ApplicationState.getLists() &&
                  ApplicationState.getLists().length > 0 && (
                    <React.Fragment>
                      {ApplicationState.getLists().map((element) => {
                        return (
                          <ListItem
                            button
                            component={NavLink}
                            to={"/list/" + element.id}
                            onClick={() => {
                              setMobileOpen(false);
                              setLoading(!loading);
                              ApplicationState.setList(element);
                            }}
                            style={{ paddingBottom: "8px", paddingTop: "8px" }}
                          >
                            <ListItemIcon style={{ minWidth: "42px" }}>
                              <ListIcon style={{ color: "#fff" }} />
                            </ListItemIcon>
                            <ListItemText primary={element.name} />
                          </ListItem>
                        );
                      })}
                    </React.Fragment>
                  )}
                <Divider style={{ borderColor: "#d4af37" }} />
                <ListItem
                  button
                  component={NavLink}
                  to="/listItemCrud"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Settings");
                  }}
                  selected={selectedMenuItem === "Settings"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <SettingsIcon style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Settings" />}
                </ListItem>
                <Divider style={{ borderColor: "#d4af37" }} />
              </Box>
            )}
          </MenuList>
        </Box>
        <Box p={5}></Box>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            justifyContent: "center",
            paddingTop: "16px",
            // backgroundColor: "#f6f6f6",
          }}
        >
          <AccountCircle
            style={{
              width: "85px",
              height: "85px",
              fontSize: "xx-large",
              color: "#d8d8d8",
            }}
          ></AccountCircle>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            justifyContent: "center",
            paddingTop: "12px",
            // backgroundColor: "#f6f6f6",
          }}
        >
          <Typography
            variant="body2"
            style={{
              color: "#fff",
              fontWeight: "600",
              padding: "8px",
              paddingTop: "0px",
              paddingBottom: "0px",
              wordBreak: "break-word",
              textAlign: "center",
            }}
          >
            {ApplicationState.getUser().name}
            <br />
            {ApplicationState.getUser().email}
            {/* {ApplicationState.getState().user.firstName +
              " " +
              ApplicationState.getState().user.lastName} */}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            justifyContent: "center",
            paddingTop: "0px",
            // backgroundColor: "#f6f6f6",
          }}
        >
          <Link
            component="button"
            onClick={logout}
            style={{ color: "#000", textDecoration: "underline" }}
            underline="hover"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ color: "#d4af37", textDecoration: "underline" }}
              >
                Log out
              </Typography>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );

  if (!ApplicationState.getUser()) {
    {
      console.log("FIS");
    }
    return (
      <>
        <Router>
          <Routes>
            <Route
              path="/login"
              element={<LoginView doLoginFunction={doLogin} />}
            ></Route>
            <Route
              path="/"
              element={<LoginView doLoginFunction={doLogin} />}
            ></Route>
          </Routes>
        </Router>
      </>
    );
  }

  return (
    <>
      <Router>
        <div style={{ display: "flex" }}>
          {["☰"].map((anchor) => (
            <React.Fragment key={anchor}>
              {/* <Hidden smUp sx={{ display: { sm: 'none', xs: 'block' } }}> */}

              <AppBar
                position="fixed"
                // className={classes.appBar}
                sx={{
                  backgroundColor: "#252525",
                  color: "#d4af37 ",
                  //width: {xs: "", sm: `calc(100% - ${drawerWidth}px)`},
                  // [theme.breakpoints.up("sm")]: {
                  //   width: `calc(100% - ${drawerWidth}px)`,
                  //   marginLeft: drawerWidth,
                  // },
                }}
              >
                <Toolbar>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <ListItem
                        button
                        onClick={handleDrawerToggle}
                        sx={{
                          paddingBottom: "20px",
                          paddingTop: "20px",
                        }}
                        size="large"
                        variant="outlined"
                      >
                        <MenuIcon style={{ color: "#d4af37" }} />
                      </ListItem>
                    </Grid>
                    <Grid item style={{maxWidth: "60%"}}>
                      <NavLink
                        to="/list"
                        onClick={() => {
                          setMobileOpen(false);
                          setSelectedMenuItem("Home");
                        }}
                        style={{ color: "#d4af37", textDecoration: "none" }}
                      >
                        {/* <img
                          src={logo}
                          style={{
                            height: "50px",
                            width: "155px",
                            margin: "8px 8px 8px 4px",
                            top: "57px",
                            left: "58px",
                            alignContent: "",
                          }}
                        /> */}
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          {ApplicationState.getList() && (
                            <Typography variant="h5" style={{ color: "#ddd", paddingTop: "4px", paddingBottom:"4px" }}>
                              <center>
                              {ApplicationState.getList().name}
                              </center>
                            </Typography>
                          )}
                        </Grid>
                      </NavLink>
                    </Grid>

                    <Grid item>
                      <ListItem
                        button
                        // component={NavLink}
                        // to="/settings"
                        onClick={(event) => {
                          setMobileOpen(false);
                          setAnchorEl2(event.currentTarget);
                          //setSelectedMenuItem("Settings");
                        }}
                        style={{ paddingBottom: "20px", paddingTop: "20px", color: "#d4af37"  }}
                      >
                        <SettingsIcon style={{ color: "#d4af37" }} />
                        
                      </ListItem>
                      <Menu
                          id="basic-menu"
                          anchorEl={anchorEl2}
                          open={openListMenu}
                          onClose={closeListMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {/* <ListItem component={NavLink} to="/listCrud" onClick={() => {setAnchorEl2(null)}}>Profile</ListItem> */}
                          <ListItem component={NavLink} to="/listCrud" onClick={() => {closeListMenu(); ApplicationState.setListToEdit(ApplicationState.getList())}}>Edit list</ListItem>
                          <MenuItem onClick={async () => {closeListMenu(); await TiivliAPI.deleteList(ApplicationState.getList().id); setShowMenuText(!showMenuText)}}>Delete list</MenuItem>
                        </Menu>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              {/* </Hidden> */}

              <Box
                sx={{
                  width: { xs: 0 },
                  flexShrink: 0,
                }}
                aria-label="mailbox folders"
              >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                {/* <Hidden smUp sx={{ display: { sm: 'none', xs: 'block' } }}> */}
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  // classes={{
                  //   paper: classes.drawerPaper,
                  // }}
                  styles={{
                    width: drawerWidth,
                    backgroundColor: "#252525",
                    borderRightWidth: "0px",
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: "#252525",
                    },
                  }}
                  style={{ borderRight: "0px solid white" }}
                  BackdropProps={{ invisible: true }}
                >
                  {drawer(anchor)}
                </Drawer>
              </Box>
              <Box sx={{ flexGrow: 1, paddingTop: { xs: "60px" } }}>
                <div />
                <Routes>
                  <Route path="/list" element={<ListView />}></Route>
                  <Route
                    path="/list/:id"
                    element={<ListView changedList={loading} />}
                  ></Route>
                  <Route
                    path="/listItemCrud"
                    element={<ListItemCrudView />}
                  ></Route>
                  <Route
                    path="/listItem/:id"
                    element={<ListItemView />}
                  ></Route>
                  <Route path="/userCrud" element={<UserCrudView />}></Route>
                  <Route path="/listCrud" element={<ListCrudView />}></Route>
                  <Route path="/" element={<ListView />}></Route>
                </Routes>
              </Box>
            </React.Fragment>
          ))}
        </div>
      </Router>
    </>
  );
}
